import React, { Fragment, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import ChangeAssociateSideSplitsDialog from './ChangeAssociateSideSplitsDialog'
import { router } from '@inertiajs/react'
import { useForm, usePage } from '@inertiajs/react'
import Alert from '@/Shared/Alert'
import Button from '@/Shared/Button'
import Checkbox from '@/Shared/Forms/Checkbox'
import Heading from '@/Shared/Forms/Heading'
import Helpers from '@/utils/helpers'
import MultipleInputBlock from '@/Shared/Forms/MultipleInputBlock'
import Radio from '@/Shared/Forms/Radio'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import Switch from '@/Shared/Forms/Switch'
import TextInput from '@/Shared/Forms/TextInput'
import TextArea from '@/Shared/Forms/TextArea'
import { Tooltip } from '@/Shared/Tooltip'
import classNames from 'classnames'

const PROLabel = ({ text }) => {
  return (
    <div className="mt-2 inline-flex overflow-hidden rounded-full border border-gray-300 shadow">
      <div className="inline-flex items-center text-sm leading-none">
        <div className="inline-flex items-center whitespace-nowrap rounded-l-full border-r border-purple-200 bg-purple-100 px-2.5 py-1.5 font-medium text-purple-800">
          <span>PRO</span>
        </div>
        <div className="inline-flex items-center whitespace-nowrap rounded-r-full bg-white bg-opacity-75 px-3 py-1.5 font-medium text-gray-800">
          <span>{text}</span>
        </div>
      </div>
    </div>
  )
}

export default function ContactEdit({ fieldToFocus, record, open, onClosed }) {
  let focusRef = useRef()
  const { roles, transaction } = usePage().props
  const [disabled, setDisabled] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [isTeam, setIsTeam] = useState(false)
  const [leadAssociateCapAvailable, setLeadAssociateCapAvailable] = useState(true)
  const [removingTeamMember, setRemovingTeamMember] = useState(false)
  const form = useForm({})
  const { clearErrors, data, errors, setData, setError } = form
  const associates = transaction.contacts.filter((tc) => tc.contact_type === 'Associate')
  const owner = record?.is_transaction_owner
  const supportableContacts = transaction.contacts
    .filter((contact) => contact.roles?.length > 0)
    .sort((a, b) => {
      if (a.first_name < b.first_name) return -1
      if (a.first_name > b.first_name) return 1
      return 0
    })
    .map((contact) => ({ value: contact.id, label: contact.full_name }))

  const [phoneTypes] = useState([
    { label: 'Mobile', value: 'Mobile' },
    { label: 'Work', value: 'Work' },
    { label: 'Home', value: 'Home' },
    { label: 'Fax', value: 'Fax' },
  ])
  const states = [
    { value: 'AK', label: 'Alaska' },
    { value: 'AL', label: 'Alabama' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DC', label: 'District of Columbia' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'GU', label: 'Guam' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'IA', label: 'Iowa' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MD', label: 'Maryland' },
    { value: 'ME', label: 'Maine' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MT', label: 'Montana' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NY', label: 'New York' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VA', label: 'Virginia' },
    { value: 'VI', label: 'Virgin Islands' },
    { value: 'VT', label: 'Vermont' },
    { value: 'WA', label: 'Washington' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WY', label: 'Wyoming' },
  ]

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollToFirstError()
    }
  }, [errors])

  useEffect(() => {
    if (open) {
      setData(loadData(record))
      checkLeadAssociateCap()
    } else {
      clearErrors()
    }
  }, [open])

  useEffect(() => checkIfTeam(), [record, data.party_representing])

  useEffect(() => {
    if (data.commission_splits) {
      let totalShare = data.commission_splits.reduce((carry, split) => carry + split.commission_pct, 0)

      if (totalShare != 100) {
        if (data.commission_splits) setError('commission_splits', `The total of all splits must add up to 100%. (Current: ${totalShare})`)
        setDisabled(true)
      } else {
        clearErrors('commission_splits')
        setDisabled(false)
      }
    }
  }, [data.commission_splits])

  const capitalize = (value) => (data.id ? value : value.replace(/^\w/, (c) => c.toUpperCase()))

  const checkLeadAssociateCap = () => {
    const leadAssociate = associates.find((contact) => contact.is_transaction_owner).contact.associate

    let expectedBrokerageCommission = transaction.commission_blre * ((100 - leadAssociate.commission_pct) / 100)
    setLeadAssociateCapAvailable(leadAssociate.cap >= expectedBrokerageCommission)
  }

  function loadData(contact) {
    return {
      ...(contact && {
        id: contact.id || null,
        transaction_id: contact.transaction_id || null,
        type: contact.contact_type || 'Contact',
        party_representing: contact.party_representing
          ? transaction.parties.filter((p) => contact.party_representing.find((pr) => pr === p))[0]
          : null,
        role_type: contact.supported_contact_id ? 'Support' : contact?.roles?.length > 0 ? 'Primary' : null,
        supported_contact_id: contact.supported_contact_id || null,
        cc_for_supported_contact: contact.cc_for_supported_contact,
        permissions: contact.type === 'Associate' && contact.permissions ? contact.permissions : 'view',
        roles:
          contact?.roles?.length > 0
            ? contact.id
              ? contact.roles?.reduce((carry, role) => {
                  if (!contact.party_representing.find((pr) => role.name == pr)) {
                    let match = roles.find(
                      (r) => r.value === (role.party_representing ? `${role.party_representing}-${role.name}` : role.name),
                    )
                    return carry.concat(match ? { ...role, name: match.value } : role)
                  }
                  return carry.concat(role)
                }, [])
              : roles.filter((role) => contact.roles.find((r) => r == role.value)).map((role) => ({ name: role.value, primary: true }))
            : [{ primary: true }],
        notes: contact.notes,
        invite: false,
        legal_title: contact.legal_title || '',
        first_name: contact.first_name || '',
        nickname: contact.nickname || '',
        middle_name: contact.middle_name || '',
        last_name: contact.last_name || '',
        suffix: contact.suffix || '',
        company: contact.company || '',
        title: contact.title || '',
        industry: contact.industry || '',
        street: contact.street || '',
        street2: contact.street2 || '',
        city: contact.city || '',
        state: contact.state || '',
        postal_code: contact.postal_code || '',
        phone: contact.phone_formatted || '',
        phone_type: contact.phone_type || '',
        email: contact.email || '',
        website: contact.website || '',
      }),
    }
  }

  const fetchIndustries = async (value) => axios.get('/api/services', { params: { query: value } })

  const checkIfTeam = () => {
    let isTeamMember = record?.contact?.type === 'Associate' && data.party_representing === transaction.type
    setIsTeam(isTeamMember)

    if (record?.contact_type === 'PRO' && isTeamMember) {
      let associatesCount = associates.length + 1
      let equalShare = Helpers.numberPrecision(100 / associatesCount, 2)
      let combinedShares = Helpers.numberPrecision(equalShare * associatesCount, 2)
      let remainder = Helpers.round(100 - combinedShares, 2)

      setData({
        ...data,
        commission_splits: Array.from(associates, (associate) => ({
          id: associate.id,
          full_name: associate.full_name,
          is_transaction_owner: associate.is_transaction_owner,
        }))
          .concat({ id: record.id, full_name: record.full_name, is_transaction_owner: false })
          .map((associate) => ({
            ...associate,
            commission_pct: associate.is_transaction_owner ? Math.round((equalShare + remainder) * 1e12) / 1e12 : equalShare,
          })),
      })
    }
  }

  const getAvailableRoles = (role) => {
    return roles
      .filter((role) => {
        const parties = transaction.parties
        const existingParty = record?.roles?.find((role) => parties.includes(role.name))

        if (existingParty) {
          const isRoleAvailableToMainRoles = ['Escrow Agent'].find((r) => r === role.value) == undefined
          if (!isRoleAvailableToMainRoles) {
            return false
          }
        }

        const isNotCooperatingPartyRole = !role.value.includes(
          data.party_representing === transaction.type ? transaction.cooperating_party : transaction.type,
        )
        return isNotCooperatingPartyRole
      })
      .concat(getCustomRoles(role))
      .filter((r) => r.value === role || !data.roles.find((cr) => cr.name === r.value))
  }

  const getCustomRoles = (role) => {
    if (role) {
      return data.roles
        .filter((cr) => cr.name === role && !roles.find((r) => r.value === cr.name))
        .map((cr) =>
          Object.assign(
            {},
            {
              value: cr.name,
              label: cr.name,
            },
          ),
        )
    }

    return []
  }

  const getSplitName = (split) => {
    let name = record.full_name
    let transactionContact = split.id && associates.find((tc) => tc.id == split.id)
    if (transactionContact) {
      name = transactionContact.contact
        ? `${transactionContact.contact.first_name} ${transactionContact.contact.last_name}`
        : transactionContact.full_name
    }

    return name + ' '
  }

  const scrollToFirstError = () => {
    let id
    for (let key in errors) {
      id = key
      if (key.indexOf('emails') >= 0) {
        id = 'emails'
      }
      break
    }

    const input = document.getElementById(id)
    const container = document.getElementById('slideover-scroll-container')

    if (input && container) {
      container.scrollTo({ behavior: 'smooth', top: input.offsetTop - (id != 'emails' ? 25 : 0) })
    }
  }

  const switchPartyRoles = (newParty) => {
    let partyRoles = data.roles.filter((role) => role.name?.includes(data.party_representing))
    return data.roles
      .filter((role) => !partyRoles.find((pr) => pr.name == role.name))
      .concat(
        partyRoles.map((role) => {
          let match = roles.find((r) => r.value == role.name.replace(data.party_representing, newParty))
          if (match) {
            return { ...match, name: match.value }
          }
        }),
      )
  }

  const onRoleChanged = (selected) => {
    clearErrors('roles')

    const parties = transaction.parties
    const selectedKeyRole = selected.find((role) => parties.includes(role.name))
    const currentKeyRole = record.roles?.find((role) => parties.includes(role.name))

    // If the current key role was removed, add it back to the selected roles
    if (currentKeyRole && !selectedKeyRole) {
      selected = [{ ...currentKeyRole, primary: true }, ...selected]
    } else if (selectedKeyRole) {
      // Set the selectedKeyRole as primary if one exists
      selected = [{ ...selectedKeyRole, primary: true }, ...selected.filter((role) => role !== selectedKeyRole)]
    }

    setData({
      ...data,
      roles: selected,
      party_representing: selectedKeyRole ? selectedKeyRole.name : data.party_representing,
    })
  }

  const onPartyRepresentingChanged = (selected) => {
    clearErrors('party_representing')
    setData({ ...data, roles: switchPartyRoles(selected?.value), party_representing: selected?.value })
  }

  const onSubmitting = (event) => {
    event.preventDefault()

    if (associates.length > 2 && record?.contact?.type === 'Associate' && data.party_representing !== transaction.type) {
      setRemovingTeamMember(true)
    } else {
      submit()
    }
  }

  const submit = (splits) => {
    if (submitting) return

    setSubmitting(true)
    setRemovingTeamMember(false)

    let transformedData = {
      ...data,
      party_representing: data.party_representing && [data.party_representing],
      roles: data.roles
        ?.filter((role) => role.name)
        .map((s) =>
          Object.assign(
            {},
            {
              name: s.name,
              primary: s.primary || false,
            },
          ),
        ),
      ...(splits || {}),
    }

    if (data.id) {
      router.patch(route('transactions.contacts.update', { transaction: transaction.id, transactionContact: record.id }), transformedData, {
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
          clearErrors()
          onClosed()
        },
        onError: (errors) => {
          clearErrors()
          setError(errors)
        },
        onFinish: () => {
          setSubmitting(false)
        },
      })
    } else {
      router.post(route('transactions.contacts.store', transaction.id), transformedData, {
        preserveState: true,
        onSuccess: () => {
          clearErrors(), onClosed()
        },
        onError: (errors) => {
          setError(errors)
        },
        onFinish: () => {
          setSubmitting(false)
        },
      })
    }
  }

  if (Object.entries(data).length === 0) {
    return <></>
  }

  if (!record) return <></>

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button type="submit" theme="solid" form="edit-contact" disabled={disabled || submitting}>
          Save Changes
        </Button>
      }
      footerLeftActions={
        !Boolean(data.id) && (
          <Checkbox
            name={`invite`}
            label="Invite to be a PRO"
            value={data.invite}
            onChange={(checked) => setData({ ...data, invite: checked })}
          />
        )
      }
      show={open}
      size="max-w-2xl"
      title="Edit"
      subTitle={record?.full_name}
      onClosed={onClosed}
      aboveMessages
    >
      <form id="edit-contact" className="space-y-8" onSubmit={onSubmitting}>
        <div className="-mx-6 border-b-[1rem] border-gray-200 px-6 pb-6">
          <Heading>Transaction Settings</Heading>

          <ul role="list" className="space-y-4">
            {!owner && (!record?.roles || record?.roles.filter((r) => transaction.parties.indexOf(r?.name || r) >= 0).length == 0) && (
              <Fragment>
                <li className={classNames(errors?.party_representing && 'bg-red-100')}>
                  <Select
                    ref={!fieldToFocus || fieldToFocus?.toLowerCase() === 'party representing' ? focusRef : null}
                    className=""
                    error={errors.party_representing}
                    label="Which party is this contact representing?"
                    name="party_representing"
                    options={transaction.parties.map((party) => ({ value: party, label: party }))}
                    placeholder="Choose one"
                    value={data.party_representing}
                    isClearable={false}
                    onChange={(selected) => onPartyRepresentingChanged(selected)}
                    required
                  />
                </li>

                <li className={classNames('space-y-1.5', errors?.role_type && 'rounded-lg border border-red-500 bg-red-100/90 px-4 py-3')}>
                  <label className="block text-sm font-medium uppercase text-gray-500">Primary or Support Role?</label>

                  <div className="flex h-8 items-center gap-x-4">
                    <div className="flex items-center gap-x-6">
                      <Radio
                        id="role_type_primary"
                        name="role_type"
                        label="Primary"
                        value={data.role_type === 'Primary'}
                        onChange={() => {
                          clearErrors('role_type', 'supported_contact_id', 'cc_for_supported_contact')
                          setData({ ...data, role_type: 'Primary', supported_contact_id: null, cc_for_supported_contact: null })
                        }}
                      />

                      <Radio
                        id="role_type_support"
                        name="role_type"
                        label="Support"
                        value={data.role_type === 'Support'}
                        onChange={() => {
                          clearErrors('role_type', 'roles')
                          setData({ ...data, role_type: 'Support', roles: [{ primary: true }], cc_for_supported_contact: true })
                        }}
                      />
                    </div>

                    {Boolean(data.role_type) && (
                      <Button
                        theme="outline-sm"
                        buttonClasses="!py-1 !px-2.5"
                        onClick={() => {
                          clearErrors('role_type', 'roles', 'supported_contact_id', 'cc_for_supported_contact')

                          setData({
                            ...data,
                            role_type: null,
                            roles: [{ primary: true }],
                            supported_contact_id: null,
                            cc_for_supported_contact: null,
                          })
                        }}
                      >
                        <div className="group flex items-center gap-2">
                          <i className="far fa-times text-xl leading-none text-gray-400 group-hover:text-gray-800"></i>
                          <span className="text-sm uppercase text-gray-500 group-hover:text-gray-800">Clear</span>
                        </div>
                      </Button>
                    )}
                  </div>

                  {errors.role_type && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.role_type }}></div>}
                </li>
              </Fragment>
            )}

            {data.role_type === 'Primary' && (
              <li className={classNames(errors?.roles && 'rounded-lg border border-red-500 bg-red-100/90 px-4 py-3')}>
                <label className="-mb-1.5 block text-sm font-medium uppercase text-gray-500">Select Primary Role(s)</label>

                <MultipleInputBlock
                  ref={fieldToFocus?.toLowerCase() === 'role' ? focusRef : null}
                  data={data.roles}
                  containerClassName="-mb-1"
                  onChange={(value) => onRoleChanged(value)}
                  hasPrimary={false}
                  render={({ focusRef, data: blockData, onChange }) => (
                    <Fragment>
                      <div className="flex-1">
                        <Select
                          ref={focusRef}
                          classes="sm:flex-grow sm:mt-0"
                          name="role"
                          options={getAvailableRoles(blockData.name)}
                          placeholder="None"
                          value={blockData.name}
                          isClearable={false}
                          onChange={(selected) => onChange(selected && selected.value, 'name')}
                          creatable
                          disabled={
                            (owner && blockData.name === `${transaction.type}-Agent`) ||
                            (data.id && transaction.parties.indexOf(blockData.name) >= 0)
                          }
                        />
                      </div>

                      {(!isTeam || !data.roles.find((r) => r.name === `${transaction.type}-Agent`)) && (
                        <div className="ml-3">
                          <Switch
                            accessibleText="Set as Primary"
                            model={blockData.primary ?? false}
                            vertical
                            onChange={(selected) => onChange(selected, 'primary')}
                            disabled={record?.roles?.find(
                              (role) =>
                                (`${role.party_representing}-${role.name}` === blockData.name || role.name === blockData.name) &&
                                role.primary,
                            )}
                          >
                            <span className="text-sm uppercase text-gray-600">Primary</span>
                          </Switch>
                        </div>
                      )}
                    </Fragment>
                  )}
                />

                {errors.roles && <div className="mt-1 font-medium text-red-600" dangerouslySetInnerHTML={{ __html: errors.roles }}></div>}
              </li>
            )}

            {data.role_type === 'Support' && (
              <li className={classNames('space-y-4', errors?.party_representing && 'bg-red-100')}>
                <Select
                  ref={!fieldToFocus || fieldToFocus?.toLowerCase() === 'supported contact' ? focusRef : null}
                  className=""
                  name="supported_contact_id"
                  label={
                    <div>
                      <div>
                        Contact Supporting <span className="pl-1 text-red-600">*</span>
                      </div>
                      <div className="font-normal normal-case text-blue-500">
                        <i className="far fa-info-circle"></i> Supported contact must have a primary role to be eligible.
                      </div>
                    </div>
                  }
                  placeholder="Select One"
                  options={supportableContacts}
                  error={errors.supported_contact_id}
                  value={data.supported_contact_id}
                  onChange={(selected) => setData({ ...data, supported_contact_id: selected?.value })}
                  isClearable={false}
                />

                <Checkbox
                  name="cc_for_supported_contact"
                  label="Copy on Emails Sent to Supported Contact"
                  value={data.cc_for_supported_contact}
                  onChange={(selected) => setData({ ...data, cc_for_supported_contact: selected })}
                />

                <div>
                  <TextInput
                    ref={fieldToFocus?.toLowerCase() === 'title' ? focusRef : null}
                    classes="mb-0"
                    label="Job Title"
                    name="title"
                    value={data.title}
                    onChange={(value) => setData({ ...data, title: value })}
                    clearable
                  />

                  {record.contact?.title && <PROLabel text={record.contact.title} />}
                </div>
              </li>
            )}
          </ul>
        </div>

        {!owner && isTeam && (
          <div className="-mx-6 border-b-[2rem] border-gray-200 px-6 pb-6">
            <div className="mt-4 space-y-4">
              <Heading>Co-Service Settings</Heading>

              <ul role="list" className="divide-y divide-gray-300 rounded-md border border-gray-300 bg-white">
                <li className={classNames('py-3 pl-3 pr-4', errors?.permissions && 'bg-red-100')}>
                  <Select
                    ref={fieldToFocus?.toLowerCase() === 'permissions' ? focusRef : null}
                    error={errors.permissions}
                    label="Specify permissions for this contact"
                    className=""
                    name="permissions"
                    options={[
                      { value: 'view', label: 'View' },
                      { value: 'edit', label: 'View & Edit' },
                      { value: 'admin', label: 'Admin (Full Access)' },
                    ]}
                    placeholder="Choose one"
                    value={data.permissions}
                    isClearable={false}
                    onChange={(selected) => {
                      clearErrors('permissions')
                      setData({ ...data, permissions: selected && selected.value })
                    }}
                  />
                </li>
              </ul>

              {record?.contact_type === 'PRO' && (
                <Alert
                  type="warning"
                  heading="Co-Service Alert"
                  subtext={
                    <p className="font-normal">
                      You are about to add this Associate as a Co-Service Associate. Continuing with this action will grant them{' '}
                      <span className="font-semibold uppercase text-blue-500">{data.permissions}</span> access to this transaction.
                    </p>
                  }
                />
              )}
            </div>
          </div>
        )}

        {!owner && (
          <Fragment>
            <div className="mb-5">
              <Heading>Contact Information</Heading>

              {record.contact && (
                <Alert
                  className="mb-3"
                  heading="This contact is displaying data maintained by a PRO."
                  subtext="Entering information in any of the below fields will override the PRO's data for this transaction & contact <u>only</u>."
                />
              )}

              <div className="mb-4">
                <TextArea
                  label="Notes"
                  name="notes"
                  value={data.notes}
                  error={errors.notes}
                  onChange={(value) => setData({ ...data, notes: value })}
                />
              </div>

              <div className="mb-4">
                <Select
                  label={
                    <div className="sm:flex sm:items-center sm:gap-1.5">
                      <div className="flex items-center gap-1.5">
                        <span>Legal Title</span>

                        <Tooltip label="This will be appended to this contact's name whenever it is used." placement="bottom">
                          <i className="far fa-question-circle cursor-help text-base text-orange-500"></i>
                        </Tooltip>
                      </div>

                      <span className="font-normal normal-case">(i.e. Personal Representative, Executor, Executrix, etc.)</span>
                    </div>
                  }
                  name="legal_title"
                  options={[
                    { label: 'Personal Representative', value: 'Personal Representative' },
                    { label: 'Power of Attorney', value: 'Power of Attorney' },
                    { label: 'Executor', value: 'Executor' },
                    { label: 'Executrix', value: 'Executrix' },
                    { label: 'Trustee', value: 'Trustee' },
                  ]}
                  placeholder="Select or Type to Create"
                  value={data.legal_title}
                  onChange={(selected) => setData({ ...data, legal_title: selected?.value })}
                  creatable
                />
              </div>

              <div className="space-y-4 sm:mb-4 sm:flex sm:gap-4 sm:space-y-0">
                <div className="flex-1">
                  <TextInput
                    ref={fieldToFocus?.toLowerCase() === 'first name' ? focusRef : null}
                    classes="mb-0"
                    label="First Name"
                    name="first_name"
                    value={data.first_name}
                    error={errors.first_name}
                    onChange={(value) => setData({ ...data, first_name: capitalize(value) })}
                    required={!record.contact}
                    clearable
                  />

                  {record.contact?.first_name && <PROLabel text={record.contact.first_name} />}
                </div>

                <div className="sm:w-56">
                  <TextInput
                    ref={fieldToFocus?.toLowerCase() === 'nickname' ? focusRef : null}
                    classes="mb-0"
                    label={
                      <div className="flex items-center gap-1.5">
                        <span>Nickname</span>

                        <Tooltip label="This will be used instead of this contact's first name when sending emails." placement="bottom">
                          <i className="far fa-question-circle cursor-help text-base text-orange-500"></i>
                        </Tooltip>
                      </div>
                    }
                    name="nickname"
                    value={data.nickname}
                    error={errors.nickname}
                    onChange={(value) => setData({ ...data, nickname: capitalize(value) })}
                    clearable
                  />

                  {record.contact?.nickname && <PROLabel text={record.contact.nickname} />}
                </div>
              </div>

              <div className="mb-4">
                <TextInput
                  ref={fieldToFocus?.toLowerCase() === 'middle name' ? focusRef : null}
                  classes="mb-0"
                  label="Middle Name"
                  name="middle_name"
                  value={data.middle_name}
                  onChange={(value) => setData({ ...data, middle_name: capitalize(value) })}
                  clearable
                />

                {record.contact?.middle_name && <PROLabel text={record.contact.middle_name} />}
              </div>

              <div className="mb-4">
                <TextInput
                  ref={fieldToFocus?.toLowerCase() === 'last name' ? focusRef : null}
                  classes="mb-0"
                  label="Last Name"
                  name="last_name"
                  value={data.last_name}
                  error={errors.last_name}
                  onChange={(value) => setData({ ...data, last_name: capitalize(value) })}
                  clearable
                  required={!record.contact}
                />

                {record.contact?.last_name && <PROLabel text={record.contact.last_name} />}
              </div>

              <div className="mb-4">
                <Select
                  ref={fieldToFocus?.toLowerCase() === 'suffix' ? focusRef : null}
                  label={
                    <div className="sm:flex sm:items-center sm:gap-1.5">
                      <div className="flex items-center gap-1.5">
                        <span>Suffix</span>
                      </div>

                      <span className="ml-1.5 font-normal normal-case">(i.e. Jr., Sr., III, etc.)</span>
                    </div>
                  }
                  name="suffix"
                  options={[
                    { value: 'Jr', label: 'Jr.' },
                    { value: 'Sr', label: 'Sr.' },
                    { value: 'I', label: 'I' },
                    { value: 'II', label: 'II' },
                    { value: 'III', label: 'III' },
                    { value: 'IV', label: 'IV' },
                    { value: 'Esq', label: 'Esq.' },
                  ]}
                  placeholder="Select or Type to Create"
                  value={data.suffix}
                  onChange={(selected) => setData({ ...data, suffix: selected?.value || '' })}
                  creatable
                />

                {record.contact?.suffix && <PROLabel text={record.contact.suffix} />}
              </div>

              {!data.associate && (
                <Fragment>
                  <div className="mb-4">
                    <Select
                      ref={fieldToFocus?.toLowerCase() === 'industry' ? focusRef : null}
                      className="mb-0"
                      label="Industry"
                      name="industry"
                      value={data.industry}
                      onChange={(selected) => setData({ ...data, industry: selected ? selected.name || selected.label : '' })}
                      onInputChanged={(value) => fetchIndustries(value)}
                      async
                      creatable
                    />

                    {record.contact?.industry && <PROLabel text={record.contact.industry} />}
                  </div>

                  <div className="mb-4">
                    <TextInput
                      ref={fieldToFocus?.toLowerCase() === 'company' ? focusRef : null}
                      classes="mb-0"
                      label="Company"
                      name="company"
                      value={data.company}
                      error={errors.company}
                      onChange={(value) => setData({ ...data, company: value })}
                      clearable
                    />

                    {record.contact?.company && <PROLabel text={record.contact.company} />}
                  </div>

                  {data.role_type === 'Primary' && (
                    <div className="mb-4">
                      <TextInput
                        ref={fieldToFocus?.toLowerCase() === 'title' ? focusRef : null}
                        classes="mb-0"
                        label={
                          <div className="sm:flex sm:items-center sm:gap-1.5">
                            <div className="flex items-center gap-1.5">
                              <span>Job Title</span>
                            </div>

                            <span className="ml-1.5 font-normal normal-case">(i.e. Assistant, Director, Consultant, etc.)</span>
                          </div>
                        }
                        name="title"
                        value={data.title}
                        onChange={(value) => setData({ ...data, title: value })}
                        clearable
                      />

                      {record.contact?.title && <PROLabel text={record.contact.title} />}
                    </div>
                  )}
                </Fragment>
              )}

              <div className="mb-4 flex items-start gap-4">
                <div className="flex-1">
                  <TextInput
                    ref={fieldToFocus?.toLowerCase() === 'phone' ? focusRef : null}
                    classes="mb-0"
                    label="Phone Number"
                    name="phone"
                    placeholder="Number"
                    value={data.phone ?? ''}
                    onChange={(value) => setData({ ...data, phone: value })}
                    clearable
                  />

                  {record.contact?.phone_formatted && <PROLabel text={record.contact.phone_formatted} />}
                </div>

                <div>
                  <Select
                    classes="w-40 mb-0"
                    label="Type"
                    name="phone_type"
                    options={phoneTypes}
                    placeholder="Type"
                    value={data.phone_type}
                    onChange={(selected) => setData({ ...data, phone_type: selected && selected.value })}
                    creatable
                  />

                  {record.contact?.phone_type && <PROLabel text={record.contact.phone_type} />}
                </div>
              </div>

              <div className="mb-4">
                <TextInput
                  ref={fieldToFocus?.toLowerCase() === 'email' ? focusRef : null}
                  classes="mb-0"
                  label={
                    <div className="flex items-center gap-1.5">
                      <span>Email</span>

                      <Tooltip
                        label="In order to send a templated email to this contact, this field must contain a valid email address."
                        placement="bottom"
                      >
                        <i className="far fa-question-circle cursor-help text-base text-orange-500"></i>
                      </Tooltip>
                    </div>
                  }
                  name="email"
                  error={errors.email}
                  value={data.email}
                  onChange={(value) => setData({ ...data, email: value })}
                  clearable
                />

                {record.contact?.email && <PROLabel text={record.contact.email} />}
              </div>

              <div className="mb-4">
                <TextInput
                  ref={fieldToFocus?.toLowerCase() === 'website' ? focusRef : null}
                  classes="mb-0"
                  label="Website"
                  name="website"
                  error={errors.website}
                  value={data.website}
                  onChange={(value) => setData({ ...data, website: value })}
                  clearable
                />

                {record.contact?.website && <PROLabel text={record.contact.website} />}
              </div>
            </div>

            <div className="mb-5">
              <Heading>Address</Heading>

              <div className="mb-4">
                <TextInput
                  ref={fieldToFocus?.toLowerCase() === 'address' ? focusRef : null}
                  label="Street"
                  name="street"
                  classes="mb-4"
                  value={data.street}
                  onChange={(value) => setData({ ...data, street: value })}
                  clearable
                />

                <TextInput
                  label="Apartment/Suite/Unit #"
                  name="street2"
                  classes="mb-0"
                  value={data.street2}
                  onChange={(value) => setData({ ...data, street2: value })}
                  clearable
                />

                {record.contact?.street && (
                  <PROLabel text={record.contact.street + (record.contact.street2 && `, ${record.contact.street2}`)} />
                )}
              </div>

              <div className="mb-4">
                <TextInput
                  label="City"
                  name="city"
                  classes="mb-0"
                  value={data.city}
                  onChange={(value) => setData({ ...data, city: value })}
                  clearable
                />

                {record.contact?.city && <PROLabel text={record.contact.city} />}
              </div>

              <div className="mb-4">
                <Select
                  label="State"
                  name="state"
                  classes="mb-0"
                  options={states}
                  placeholder="Select One"
                  value={data.state}
                  onChange={(selected) => setData({ ...data, state: selected && selected.value })}
                />

                {record.contact?.state && <PROLabel text={record.contact.state} />}
              </div>

              <div className="mb-4">
                <TextInput
                  label="Zip Code"
                  name="postal_code"
                  classes="mb-0"
                  value={data.postal_code ?? ''}
                  onChange={(value) => setData({ ...data, postal_code: value })}
                  clearable
                />

                {record.contact?.postal_code && <PROLabel text={record.contact.postal_code} />}
              </div>
            </div>
          </Fragment>
        )}
      </form>

      <ChangeAssociateSideSplitsDialog
        contactRemoving={{
          ...data,
          first_name: record.first_name || record.contact?.first_name,
          last_name: record.last_name || record.contact?.last_name,
        }}
        transaction={transaction}
        open={removingTeamMember}
        onClose={() => {
          setRemovingTeamMember(false)
        }}
        onApprove={(splits) => submit(splits)}
      />
    </SlideOver>
  )
}
